// extracted by mini-css-extract-plugin
export var heroImageContainer = "Hero-module--heroImageContainer--3IL3I";
export var personal = "Hero-module--personal--1So23";
export var rowWrapper = "Hero-module--row-wrapper--1pJIU";
export var one = "Hero-module--one--XfSq_";
export var homepage = "Hero-module--homepage--3voWz";
export var textWrapper = "Hero-module--textWrapper--1H-Nu";
export var imageWrapper = "Hero-module--imageWrapper--2oml8";
export var textRow = "Hero-module--textRow--2f-QB";
export var center = "Hero-module--center--1EdKz";
export var text = "Hero-module--text--3xeAo";
export var arrowPointer = "Hero-module--arrowPointer--3OQF5";
export var heroCaption = "Hero-module--heroCaption--1xGdz";
export var textRight = "Hero-module--text-right--2E3x-";
export var rightAuto = "Hero-module--right-auto--1pi_y";