import React from "react"
import { Link, graphql } from "gatsby"
import Seo from "../components/SEO"
import Hero from "../components/Hero/Hero"
import Intro from "../components/IntroText/IntroText"
// import TwoColumn from "../components/TwoColumn/TwoColumn"

const HomePage = ({ data }) => {

  // Grab variable from .md file
  const hero = data.hero.edges[0].node.frontmatter.heroImage.childImageSharp.gatsbyImageData;
  const heroAlt = data.hero.edges[0].node.frontmatter.heroImageAlt;
  const introText = data.hero.edges[0].node.frontmatter.intro;
  const identifier = data.hero.edges[0].node.frontmatter.identifier;

  // const twoColumnOnIndex = data.twoColumn.edges.filter(item => item.node.frontmatter.page === 'index')
  // console.log("twoColumnOnIndex", twoColumnOnIndex);

  const positions = {
    rowOneWrapper: {
      classes: "row-wrapper one",
      TextRow1: {
        classes: "",
          text1: {
            value: "A",
            classes: "twenty ten"
          },
          text2: {
            value: "Man",
            classes: "text-right"
          }
      },
      TextRow2: {
        classes: "empty",
          text1: {
            value: "",
            classes: ""
          },
          text2: {
            value: "",
            classes: ""
          }
      },
    },
    rowTwoWrapper: {
      classes: "row-wrapper two",
      TextRow1: {
        classes: "center",
          text1: {
            value: "Who",
            classes: "ten Rightauto"
          },
          text2: {
            value: "Fell",
            classes: "twenty text-right"
          }
      },
      TextRow2: {
        classes: "center",
          text1: {
            value: "In Love",
            classes: "seventy"
          },
          text2: {
            value: "With",
            classes: "twenty"
          }
      },
      TextRow3: {
        classes: "",
          text1: {
            value: "Solving",
            classes: "thirty"
          },
          text2: {
            value: "Problems",
            classes: "thirty"
          }
      },
    }
  }

  return (
    <>
      <Seo title={"Homepage"} description={"Eli Broad was a man who fell in love with solving problems "} />
      <Hero heroimage={hero} heroimagealt={heroAlt} identifier={identifier} positions={positions} />
      <Intro introText={introText}/>
      <div class="continue-link">
        <Link to="/personal-life">Continue to Personal Life</Link>
      </div>
      {/* <TwoColumn content={twoColumnOnIndex}/>
      <TwoColumn content={twoColumnOnIndex} reverseOnMobile={true}/> */}
    </>
  )
}

export default HomePage

export const query = graphql`
  {
    hero: allMarkdownRemark(filter: {frontmatter: {identifier: {eq: "homepage"}}}) {
      edges {
        node {
          frontmatter {
            heroImage {
              childImageSharp {
                gatsbyImageData(formats: AUTO, layout: FULL_WIDTH, placeholder: BLURRED)
              }
            }
            heroImageAlt
            identifier
            intro
            title
            description
          }
        }
      }
    }

    twoColumn: allMarkdownRemark(filter: {frontmatter: {page: {eq: "index"}, component: {eq: "twocolumn"}}}) {
      edges {
        node {
          ...twoColumnQuery
        }
      }
    }

  }
`


