import React from "react"
import * as classes from "./IntroText.module.css"

const Intro = ({introText}) => {

    return (
        <div className={classes.introContainer}>
            <p>{introText}</p>
        </div>
      )
  }

  export default Intro