import React from "react"
import * as classes from "./Hero.module.scss"
import { GatsbyImage, getImage } from "gatsby-plugin-image"



const Hero = ({heroimage, heroimagealt, positions = [], identifier, description}) => {
    const img = getImage(heroimage)

    console.log()

    // Position Variables

    // Row one Variables
    const wrapperOne = positions.rowOneWrapper.classes;
    const wrapperTwo = positions.rowTwoWrapper.classes;

    const rowoneClass = positions.rowOneWrapper.TextRow1.classes;

    const rowoneTextOneClass = positions.rowOneWrapper.TextRow1.text1.classes;
    const rowoneTextOneValue = positions.rowOneWrapper.TextRow1.text1.value;
    const rowoneTextTwoClass = positions.rowOneWrapper.TextRow1.text2.classes;
    const rowoneTextTwoValue = positions.rowOneWrapper.TextRow1.text2.value;

    // Row Two variables
    const rowtwoClass = positions.rowOneWrapper.TextRow2.classes;

    const rowtwoTextOneClass = positions.rowOneWrapper.TextRow2.text1.classes;
    const rowtwoTextOneValue = positions.rowOneWrapper.TextRow2.text1.value;
    const rowtwoTextTwoClass = positions.rowOneWrapper.TextRow2.text2.classes;
    const rowtwoTextTwoValue = positions.rowOneWrapper.TextRow2.text2.value;

    // Row Three variables
    const rowthreeClass = positions.rowTwoWrapper.TextRow1.classes;

    const rowthreeTextOneClass = positions.rowTwoWrapper.TextRow1.text1.classes;
    const rowthreeTextOneValue = positions.rowTwoWrapper.TextRow1.text1.value;
    const rowthreeTextTwoClass = positions.rowTwoWrapper.TextRow1.text2.classes;
    const rowthreeTextTwoValue = positions.rowTwoWrapper.TextRow1.text2.value;

    // Row Four variables
    const rowfourClass = positions.rowTwoWrapper.TextRow2.classes;

    const rowfourTextOneClass = positions.rowTwoWrapper.TextRow2.text1.classes;
    const rowfourTextOneValue = positions.rowTwoWrapper.TextRow2.text1.value;
    const rowfourTextTwoClass = positions.rowTwoWrapper.TextRow2.text2.classes;
    const rowfourTextTwoValue = positions.rowTwoWrapper.TextRow2.text2.value;

    // Row Five variables
    const rowfiveClass = positions.rowTwoWrapper.TextRow3.classes;

    const rowfiveTextOneClass = positions.rowTwoWrapper.TextRow3.text1.classes;
    const rowfiveTextOneValue = positions.rowTwoWrapper.TextRow3.text1.value;
    const rowfiveTextTwoClass = positions.rowTwoWrapper.TextRow3.text2.classes;
    const rowfiveTextTwoValue = positions.rowTwoWrapper.TextRow3.text2.value;

    return (

        <>
        <div id={identifier} className={classes.heroImageContainer} style={{
            backgroundImage: "url(" + img.images.fallback.src + ")",
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat'
          }}>
            <div className={classes.textWrapper}>
                    <div className={wrapperOne  ? wrapperOne : ''}>
                        <div className={[classes.textRow, `${rowoneClass  ? rowoneClass : ''}`].join(' ')}>
                            <div className={[classes.text, `${rowoneTextOneClass  ? rowoneTextOneClass : ''}`].join(' ')}>{rowoneTextOneValue}</div>
                            <div className={[classes.text, `${rowoneTextTwoClass  ? rowoneTextTwoClass : ''}`].join(' ')}>{rowoneTextTwoValue}</div>
                        </div>
                        <div className={[classes.textRow, `${rowtwoClass  ? rowtwoClass : ''}`].join(' ')}>
                            <div className={[classes.text, `${rowtwoTextOneClass  ? rowtwoTextOneClass : ''}`].join(' ')}>{rowtwoTextOneValue}</div>
                            <div className={[classes.text, `${rowtwoTextTwoClass  ? rowtwoTextTwoClass : ''}`].join(' ')}>{rowtwoTextTwoValue}</div>
                        </div>
                    </div>
                    <div className={[`${wrapperTwo  ? wrapperTwo : ''}`].join(' ')}>
                        <div className={[classes.textRow, `${rowthreeClass  ? rowthreeClass : ''}`].join(' ')}>
                            <div className={[classes.text, `${rowthreeTextOneClass  ? rowthreeTextOneClass : ''}`].join(' ')}>{rowthreeTextOneValue}</div>
                            <div className={[classes.text, `${rowthreeTextTwoClass  ? rowthreeTextTwoClass : ''}`].join(' ')}>{rowthreeTextTwoValue}</div>
                        </div>
                        <div className={[classes.textRow, `${rowfourClass  ? rowfourClass : ''}`].join(' ')}>
                            <div className={[classes.text, `${rowfourTextOneClass  ? rowfourTextOneClass : ''}`].join(' ')}>{rowfourTextOneValue}</div>
                            <div className={[classes.text, `${rowfourTextTwoClass  ? rowfourTextTwoClass : ''}`].join(' ')}>{rowfourTextTwoValue}</div>
                        </div>
                        <div className={[classes.textRow, `${rowfiveClass  ? rowfiveClass : ''}`].join(' ')}>
                            <div className={[classes.text, `${rowfiveTextOneClass  ? rowfiveTextOneClass : ''}`].join(' ')}>{rowfiveTextOneValue}</div>
                            <div className={[classes.text, `${rowfiveTextTwoClass  ? rowfiveTextTwoClass : ''}`].join(' ')}>{rowfiveTextTwoValue}</div>
                        </div>
                    </div>
            </div>
            <div className={classes.imageWrapper}>
                <GatsbyImage className="art-directed" image={img} alt={heroimagealt} />
            </div>
            <a  role="button" aria-label="next"  href="#nextsection" className={classes.arrowPointer}><span></span></a>
            <div className={classes.heroCaption}>{description ? description : ''}</div>
        </div>
        <a name="nextsection" href="!#" aria-hidden="true"><i aria-hidden="true"></i> </a>
        </>
      )
  }


export default Hero
